.email-subscribe-form {
  width: 80%;
  height: auto;
  padding: 5px;
  position: absolute;
  border-radius: 10px;
}


.email-subscribe-form input {
  width: 79%;
  height: 35px !important;
  position: relative;
  border: none;
  background-color: inherit;
  margin-bottom: 0px;
}

.email-subscribe-form input:focus {
  border: none;
  border-radius: inherit;
  background-color: inherit;
  margin-bottom: 0px;
}

.new-email-input {
  display: inline-block;
  width: 99%;
  padding: 18px 28% 18px 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: 65px;
  border-radius: 20px;
  border: none;
  background: #FFFFFF;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 10px;


  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #0A253E;
}

@media (max-width: 820px) {
  .new-email-input{
    display: inline-block;
    width: 99%;
    padding: 7px 30% 7px 11px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    height: 45px;
    border-radius: 20px;
    border: none;
    background: #FFFFFF;
    box-shadow: inset 0px 2px 2px rgb(0 0 0 / 8%);
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 30px;
    color: #0A253E;
    }
    
}


.new-email-input,
.new-email-input::-webkit-input-placeholder {
  line-height: 3;
}

.new-email-input::placeholder {
  color: #0A253E;
  opacity: 0.4;
}

.new-email-bt {
  margin-top: 5px;
  height: 55px;
  margin-left: -134px;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
  padding: 13px 32px;
  border-radius: 8px;
  /* Only necessary for .btn */
  border: none;
  cursor: pointer;
  /* background-color: #F02469; */
  color: #fff;

  font-family: 'Poppins';
  font-style: normal;
  text-align: center;


  border: 1px solid #F02469;
	background-color: #bc1d53;
	background-image: -moz-linear-gradient(top, #F02469 0%, #F02469 100%); 
	background-image: -webkit-linear-gradient(top, #F02469 0%,#F02469 100%); 
	background-image: linear-gradient(to bottom, #F02469 0%,#F02469 100%); 
	background-size: 300px;
	background-repeat: no-repeat;
	background-position: 0%;
	-webkit-transition: background 300ms ease-in-out;
	transition: background 300ms ease-in-out;
}

@media (max-width: 820px) {

  .new-email-bt{
    margin-top: 5px;
    height: 36px;
    margin-left: -88px;
    font-size: 16px;
    display: inline-block;
    font-weight: 400;
    padding: 0px 10px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    /* background-color: #F02469; */
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    text-align: center;
    border: 1px solid #F02469;
    background-color: #bc1d53;
    background-image: linear-gradient(to bottom, #F02469 0%,#F02469 100%);
    background-size: 181px;
    background-repeat: no-repeat;
    background-position: 0%;
    transition: background 300ms ease-in-out;
  }
}

.new-email-bt:hover ,  .new-email-bt:checked , .new-email-bt:active{
	border: 1px solid #bc1d53;
	color: #ffffff;
	background-position: -200%;
	transition: background 300ms ease-in-out;
  }

