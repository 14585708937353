.h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    color: #0A253E;
}

.onboarding-sub-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #52606F;
}

.onboarding-bg{
    background-image: url(../../assets/img/clipart/path-line.png);
    background-repeat: no-repeat;
    background-size: auto 10%;
    background-position: center;
}