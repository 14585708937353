.contact-us-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 63px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #0A253E;
}

.contact-us-curveLineAarrowRoundVerticalImg{
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -50px;
    margin-right: 0px;
    width: 50px;
    height: 100px;
}