.circle-left {
  background: rgb(190 198 199 / 25%);
  display: inline-block;

  height: 100px;
  width: 50px;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;

  margin-top: -3%;
  margin-left: 97.7%;
  position: absolute;
}

.sectionFreeDemo-bg {
  background-color: #ECEFF6;
}

.sectionFreeDemo-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 44px;
  line-height: 66px;
  color: #0A253E;
}

.sectionFreeDemo-title-bold {
  font-family: 'Poppins-SemiBold';
  font-weight: 600;
}