*,
*::after,
*::before {
	box-sizing: border-box;
}

.button-b5 {
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 6px;

	font-family: 'Poppins';
	font-style: normal;
	font-size: 15px;
	text-align: center;	
}

.button-5 {
	border: 1px solid #F02469;
	border-radius: 5px;
	color: #ffffff;
	background-color: #bc1d53;
	background-image: -moz-linear-gradient(top, #F02469 0%, #F02469 100%); 
	background-image: -webkit-linear-gradient(top, #F02469 0%,#F02469 100%); 
	background-image: linear-gradient(to bottom, #F02469 0%,#F02469 100%); 
	background-size: 300px;
	background-repeat: no-repeat;
	background-position: 0%;
	-webkit-transition: background 300ms ease-in-out;
	transition: background 300ms ease-in-out;
  }
  
  .button-5:hover {
	border: 1px solid #bc1d53;
	color: #ffffff;
	background-position: -200%;
	transition: background 300ms ease-in-out;
  }
