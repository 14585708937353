.section-circle {
  position: absolute;
  margin: 1.5% auto auto 4%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.modal {
  display: block !important;
  opacity: 1;
}

.modal-content {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12) !important;
  border: none !important;
}

.modal-close-button {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  color: white !important;
}

.modal-body {
  /* box-shadow: inset 0 0 15px #000000 !important; */
}

.modal-header {
  background-color: #f0f0f0 !important;
  color: white !important;
}

.modal-header-name {
  color: aqua !important;
}

.modal-checkbox {
  height: 20px;
}