.custom-switch-label {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    color: #0A253E;
}


*,
*:after,
*:before {
    box-sizing: border-box;
}

