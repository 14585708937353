.rec.rec-dot,
.rec.rec-dot:hover {
	background: #D9D9D9;
	box-shadow: 0 0 1px 2px #D9D9D9 !important;
}



.rec.rec-dot_active,
.rec.rec-dot_active:hover {
	background: #F02469;
	box-shadow: 0 0 1px 2px #F02469 !important;
}



.testimonials-main-heading {
	font-weight: 300;
	font-size: 48px;
	line-height: 72px;
	text-align: center;
	letter-spacing: 0.2px;
	color: #0B2B33;
}

.testimonials-card {
	background: #FFFFFF !important;
	box-shadow: 0 20px 60px rgb(10 30 81 / 4%) !important;
	border-radius: 10px;
}

.testimonials-card .testimonials-description{
	margin-bottom: 0px;
	font-size: 22px;
	line-height: 28px;
	font-weight: 300;
	height: 11rem;
	color: #0B2B33;
}

.testimonials-card .readmore {
	color: #F02469;
}

.testimonials-card-title {
	padding-left: 1rem;
	margin-bottom: 0px;
}

.testimonials-card-sub-title {
	white-space: nowrap;
	color: #52606F;
	font-size: 1em;
	font-weight: 500;
}


.testimonials-card-profile {
	width: 65px;
	height: 65px;
	/* border: 1px solid #000; */
	border-radius: 50%;
	overflow: hidden;
}

.testimonials-card img {
	/* padding: 0.5rem; */
	width: 100%;
	height: 100%;
	background: #FFFFFF;
}



.flip-card-lable {
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	display: block;
	width: 100%;
	height: 240px;
	cursor: pointer;
}

.flip-card {
	/*     position: relative; */
	height: 100%;
	width: 100%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: all 600ms;
	transition: all 600ms;
	z-index: 20;
}

.flip-card div {
	position: absolute;
	height: 100%;
	width: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	background: #FFF;
	box-shadow: 0 20px 60px rgb(10 30 81 / 4%);
	border-radius: 10px;
}

.flip-card .back {
	background: #222;
	color: #fff;
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	border-radius: 10px;
	box-shadow: 0 20px 60px rgb(10 30 81 / 4%);
}

.flip-card-checkbox {
	display: none;
}

:checked+.flip-card {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}

.flip-card p {
	font-size: 22px;
	color: #0B2B33;
	font-weight: 300;
}