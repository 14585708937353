.signup-left-bg {
    background-image: url('./../../assets/img/page/laptop.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.signup-right-bg {
    background-color: #0A283E;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.signup-container {
    /* padding: 3rem 15rem; */
    /* padding: 1rem 5rem; */
    padding: 1rem 4rem;
    min-height: 600px;
}

.signup-logo {
    position: absolute;
    top: 6em;
    left: 6em;
}

/* .signup-close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 1.5rem;
    width: 1.5rem;
} */
.signup-close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 1.5rem;
    width: 2rem; /* Adjusted to give enough space for text */
    height: 2rem;
    background-color: #c1ceda;
    border-radius: 50%; /* Makes it round */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Optional: Adjust text color for better visibility */
    text-align: center;
    cursor: pointer; /* Optional: Change cursor to pointer */
}
.signup-close:hover{
    background-color: #9ba4ac;
}


.signup-card-full{
    border-radius: 20px;
    background-color: #FFF;
}

.signup-card-left {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}

.signup-card-right {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none;
    /* -webkit-box-shadow: 0 0 35px rgb(229 233 241 / 80%); */
    /* box-shadow: 0 0 35px rgb(229 233 241 / 80%); */
}

.signup-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 63px;
    /* identical to box height */

    letter-spacing: 0.2px;

    color: #0A253E;

    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.signup-sub-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #748492;
}



.signup-input {
    padding: 10px 15px;
    background: #FFFFFF;
    border: 1px solid #C1CEDA;
    border-radius: 4px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;

    display: flex;
    align-items: center;
    color: #0A253E;
}

.signup-input-invalid {
    border: 1px solid #F02469;
}

.signup-input,
.signup-input::-webkit-input-placeholder {
    font-size: 17px;
    /* line-height: 3; */
}

.signup-input::placeholder {
    color: #0A253E;
    opacity: 0.4;
}

.signup-link {
    color: #009DDC;
}

.signup-button {
    padding: 10px 15px;
    font-size: 17px;
    line-height: 24px;
}




/* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
} */

.signup-input select+i.fa {
    float: right;
    margin-top: -32px;
    margin-right: 50px;

    pointer-events: none;

    background-color: #482536;
    padding-right: 5px;
}
