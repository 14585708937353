.header-container {
  background-color: #FFFFFF !important;
  border-radius: 0 0 28px 28px;
  box-shadow: 0 4px 22px 0 rgba(31, 1, 71, 7%);
}

.navbar,
.fixed-top {
  background-color: #FFFFFF !important;
  border-radius: 0 0 28px 28px;
}

.nav-image-button {
  margin: 40px;
}

ul {
  float: left;

  margin: 0px;
  padding: 0px;
}

.top-nav-bar-menu li {
  display: inline-block;
  margin-left: 20px;
  color: #1B4A56;
  margin: auto 23px;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.top-nav-bar-menu li a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #0A253E !important;
}

.top-nav-bar-menu li:hover a {
  color: #F02469 !important;
}

/* .top-nav-bar-menu li button{
  padding: 0;
} */

.btn-group-vertical>.btn,
.btn-group>.btn {
  padding: 0%;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 1.15em;
    position: absolute;
  }
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.top-nav-bar-menu .dropdown-toggle::after {
  display: inline-block;
  margin-left: 1em;
  vertical-align: 0.255em;
  content: "";

  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}