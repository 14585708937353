  /* Square */
  .custom-animation-1 {
    display: inline-block;
    background-color: #83c1f6;
    height: 50px;
    width: 50px;
    font-size: 1px;
    padding: 1px;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
    animation: roll 20s infinite;
    transform: rotate(30deg);
    opacity: .7;
  }

  @keyframes roll {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* Square style 2 */
  .custom-animation-2 {
    display: inline-block;
    border: solid #0000001c;
    /* background: linear-gradient(180deg, #fff, #FFF); */
    height: 50px;
    width: 50px;
    font-size: 1px;
    padding: 1px;
    color: white;
    /* margin-right: 5px;
    margin-left: 5px; */
    animation: skew 18s infinite;
    transform: skew(20deg);
    animation-direction: alternate;
    opacity: .7;
  }

  @keyframes skew {
    0% {
      transform: skew(20deg, 20deg);
    }

    100% {
      transform: skew(-20deg, -20deg);
    }
  }

  /* shapes */
  .shapes {
    position: absolute;
    z-index: -1;
  }

  .shape-one {
    animation: jumpOne 3.5s infinite linear;
    animation-duration: 3.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: jumpOne;
  }

  @keyframes jumpOne {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .shape-three {
    /* left: 11%;
    top: 35%; */
    animation: jumpTwo 3.5s infinite linear;
    animation-duration: 3.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: jumpTwo;
    opacity: 0.2;
  }

  @-webkit-keyframes jumpTwo {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40% {
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes jumpTwo {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40% {
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .shape-four {
    /* left: 11%;
    top: 35%; */
    animation: jumpFour 3.5s infinite linear;
    animation-duration: 3.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: jumpFour;
    opacity: 0.2;
  }

  @-webkit-keyframes jumpFour {
    /* 0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40% {
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    } */
  }

  @keyframes jumpFour {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40% {
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(20px, 0, 0);
    }

    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }


  .spin {
    animation-name: spin;
    animation-duration: 30000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    opacity: 0.3;
  }
  
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }
  