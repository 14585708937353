.demo {
  margin-bottom: -100px;
  position: relative;
  z-index: 2;
  /* background-color: #486E78; */
  background-image: url('../../../assets/img/home/demo-background.png');
  background-size: 100% auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-size: cover;
}

/* .demo h1 {
  padding: 50px;
} */

.sectionDemo-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 43px;
  line-height: 64px;
  color: #FFFFFF;
}

.sectionDemo-title-bold {
  font-family: 'Poppins-SemiBold';
  font-weight: 500;
}