.back-to-top {
    cursor: pointer;
    position: fixed;
    display: none;
    z-index: 1000;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    background: rgba(240, 36, 105, 0.8);
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
}