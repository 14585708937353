.card-box {
    /* background-color: #fff; */
    /* box-shadow: 0px 9px 34px rgba(0, 0, 0, 0.1); */
    background: #D2E9EB;
    border-radius: 5px;
    display: -ms-flexbox;
    display: flex;
    /* margin-bottom: 1rem; */
    min-height: 80px;
    padding: 0.5rem;
    position: relative;
    width: 100%;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.card-box .card-box-icon {

    border-radius: 0.25rem;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    /* display: flex; */
    font-size: 1.875rem;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 70px;
}

.card-box .card-box-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1.8;
    -ms-flex: 1;
    flex: 1;
    padding: 0 10px;
    overflow: hidden;
}

.card-box .card-box-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #0A253E;
    line-height:1;
    margin-bottom: 0rem;
}

.card-box .card-box-description {
    display: block;
    margin-top: 0.65rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */
    letter-spacing: 0.2px;
    color: #000000;
    opacity: 0.6;
}