.section3-heading {
	font-weight: 400;
	font-size: 36px;
	line-height: 41px;
	letter-spacing: 0.2px;
	color: #0B2B33;
}

.section3-title-bold {
	font-family: 'Poppins-SemiBold';
	font-weight: 500;
}

.section3-right-image {
	width: 100%;
	background: #FFFFFF;
	box-shadow: 0px 4px 19px rgba(236, 236, 236, 0.9);
	border-radius: 27px;
}

.section3-right-image-100 {
	width: 100%;
}