@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url('./assets/font/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'),
    url('./assets/font/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url(./assets/font/poppins/Poppins-SemiBold.ttf);
}

body,
html {
  font-family: 'Poppins', sans-serif;
}

.h1 {
  font-family: 'Poppins-Bold';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.field-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: var(--color-tangaroa);
  opacity: 0.6;
  margin-bottom: 0;
}

.error-label{
  color: #dc3545!important;
  font-size: small !important;
  width: 100% !important;
}