.section1-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 3.625rem;
    line-height: 79px;
    letter-spacing: 0.2px;
    color: #0A253E;
}

.section1-title-bold {
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
}

.section1-title-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 58px;
    line-height: 79px;
    letter-spacing: 0.2px;
}


.section1-message {
    color: #52606F;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
}


.section1-arrow {
    position: absolute;
    right: 0;
    margin-top: -4rem;
}