.number-box {
    border-radius: 10px;
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    /* margin-bottom: 1rem; */
    min-height: 80px;
    padding: 0.5rem;
    position: relative;
    width: 100%;
}

/* .number-box :hover {
    margin-top: -5px;
  } */

.number-box .number-box-icon {
    width: 80px;
    height: 90px;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15); */
    /* box-shadow: 0 20px 60px rgb(10 30 81 / 9%); */
    border-radius: 20px;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.875rem;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.number-box .number-box-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1.8;
    -ms-flex: 1;
    flex: 1;
    padding: 0;
    /* padding-left: 20px; */
    overflow: hidden;
}

.number-box .number-box-text,
.number-box .progress-description {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #52606F;
    /* font-size: 0.9em; */
    font-size: 1em;
    font-weight: 500;
}

.number-box .number-box-number {
    font-family: 'Poppins';
    font-style: normal;
    color: #0A253E;
    display: block;
    margin-top: 0.25rem;
    font-weight: 600;
    font-size: 1.5em;
}

.number-down {
    background-color: #FDE3E3;
    color: #F33D3D;
    font-size: 12px;
    padding: 5px;
}

.number-up {
    background-color: #E9FAF8;
    color: #28D0B7;
    font-size: 12px;
    padding: 5px;
}