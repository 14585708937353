ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #0A253E;
  position: relative;
  padding-top: 150px;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #D1DBDD;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.footer-social-icon img {
  width: 30px;
  height: 30px;
  /* box-shadow: #F02469 0px 3px, rgba(0, 0, 0, 0.23) 0px 6px; */
}

.footer-social-icon img:hover {
  width: 30px;
  height: 30px;
  /* box-shadow:  #F02469 0px 6px 10px,#F02469 0px 3px 6px; */
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  /* margin-bottom: 40px; */
  position: relative;

}

.footer-widget-middle {
  margin-left: 25%;
}

/* .footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
} */

.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 100%;
  margin-bottom: 12px;
}

.footer-widget ul li a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;

  color: #D1DBDD;
  text-transform: capitalize;
  cursor: pointer;
}

.footer-widget ul li a:hover {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: 0.2px;

  color: #F02469;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  height: 56px;
  padding: 14px 28px;
  border: 1px solid #2E2E2E;
  color: #fff;
  background: #113353;
  border-radius: 10px;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 13px 20px;
  background: #F02469;
  border-radius: 7px;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #081F35;
  padding: 5px 0;
}

.copyright-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #D1DBDD;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.phone-link{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;

  color: #D1DBDD;
  text-transform: capitalize;
  cursor: pointer;
}

.phone-link:hover {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: 0.2px;

  color: #F02469;
}