.collaborate-hirebase-people-bg {
    background-image: url(../../assets/img/page/hirebase-people-background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom, 0px 0px;
}


.wizard-img img {
    width: 20%;
}

.wizard-text {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #0A253E;
}

.curve-line-arrow-round {
    position: absolute;
    margin-top: 0%;
    margin-left: 70%;
    height: 35%;
}

.collaborate-peopele-bg {
    background-color: #ECEFF6;
}

.collaborate-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 3.625rem;
    line-height: 79px;
    letter-spacing: 0.2px;
    color: #0A253E;
}

.collaborate-title-bold {
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
    background-color: #92DDE2;
}

.collaborate-title-underline {
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}

.collaborate-title-underline::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 2px solid #F02469;
}