.section2-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	font-size: 50px;
	line-height: 75px;
	letter-spacing: 0.2px;
	color: #0B2B33;
}

.section2-title-bold {
	font-family: 'Poppins-SemiBold';
	font-weight: 500;
}

.section2-ul li {
	font-family: 'Poppins';
	font-style: normal;
	margin: 20px;
	font-weight: 400;
	font-size: 22px;
	line-height: 40px;
	letter-spacing: 0.2px;
	color: #0A253E;
	opacity: 0.6;
	/* list-style: none; */
}

.section2-ul li::before {
	/* content: "\2022"; */
	color: #D1DBDD;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

.section2-video {
	width: 100%;
	background: #FFFFFF;
	border-radius: 27px;
	box-shadow: 0px 4px 19px rgba(236, 236, 236, 0.9);
}

.section2-title-underline {
	position: relative;
	display: inline-block;
	padding-bottom: 0px;
}

.section2-title-underline::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 20px;
	width: 80%;
	border: 2px solid #F02469;
	right: 30px;
}