.one-place-bg {
    background-image: url(../../../assets/img/page/background-img-2.png), url(../../../assets/img/page/background-img-1.png);
    /* background-image: url(../../../assets/img/page/background-img-3.svg), url(../../../assets/img/page/background-img-1.png); */
    background-repeat: no-repeat;
    background-size: contain, 100%;
    background-position: right bottom, 0px 0px;
}

.one-place-p {
    font-weight: 500;
    color: #0B2B33;
    margin-top: 30px;
}

.one-place-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 55px;
    line-height: 82px;
    letter-spacing: 0.2px;
    color: #0B2B33;
}

.one-place-title-bold {
    font-family: 'Poppins-SemiBold';
    font-weight: 500;
}

.one-place-message {
    width: 75%;
    color: #0A253E;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    opacity: 0.6;
}


@media (max-width: 1920px) {
    .one-place-right-side-image {
        width: 57%;
    }
}

@media (min-width: 1921px) {
    .one-place-right-side-image {
        width: 57%;
    }
}

@media (min-width: 2049px) {
    .one-place-right-side-image {
        width: 56%;
    }
}

@media (max-width: 820px) {
    .one-place-right-side-image {
        width: 90%;
    }
}

.one-place-title-underline {
    position: relative;
    display: inline-block;
    padding-bottom: 0px;
}

.one-place-title-underline::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 1px solid #F02469;
}