.card-top {
    background: #FFF;
    border-radius: 17px;
    display: -ms-flexbox;
    display: flex;
    /* margin-bottom: 1rem; */
    min-height: 80px;
    padding: 2.5em 1.8em 4em;
    
    
    position: relative;
    width: 100%;
    height: 100%;
    letter-spacing: 0.5px;
    font-weight: 500;
    box-shadow: 0px 4px 19px rgba(236, 236, 236, 0.9);
    border-top: 3px solid;
}

.card-top .card-top-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    /* -ms-flex-pack: center;
    justify-content: center; */
    line-height: 1.8;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
}

.card-top .card-top-title {
    min-width: 100px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    line-height:1;
}

.card-top .card-top-description {
    display: block;
    margin-top: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

    letter-spacing: 0.2px;
    color: #1E1C1C;
}