@media (max-width: 1920px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1921px) {
  .container {
    max-width: 76%;
  }
}

@media (min-width: 2049px) {
  .container {
    max-width: 60%;
  }
}

@media (max-width: 820px) {
  .modal-dialog {
    max-width: 90%;
  }
}

.text-pink {
  color: #F02469;
}

.bg-pink {
  background-color: #F02469;
}

.bg-green {
  background-color: #92DCE2;
}

.text-black {
  color: #0A253E;
}

.bg-light-gray {
  background-color: #F9F9F9;
}
.bg-hb-blue{
  background-color: #0A253E;
}

.text-white {
  color: #FFFFFF;
}

p {
  color: #0B2B33;
  font-weight: 500;
}

.custom-row {
  padding-left: 3%;
  padding-right: 3%;
}

.marquee-main {

  --space: 0rem;
}

.marquee {
  --duration: 60s;
  --gap: var(--space);

  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  gap: var(--gap);
  transform: skewY(-3deg);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: var(--gap);
  min-width: 100%;
  animation: slide var(--duration) linear infinite;
}

.hover-pointer {
  cursor: pointer;
}