/* .testimonialsNew-place-bg {
    background-image: url(../../../assets/img/page/testimonial-background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #0a253e;
} */

@media (max-width: 1920px) {
  .testimonialsNew-place-bg {
    background-image: url(../../../assets/img/page/testimonial-background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

@media (min-width: 1921px) {
  .testimonialsNew-place-bg {
    background-image: url(../../../assets/img/page/testimonial-background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

@media (min-width: 2049px) {
  .testimonialsNew-place-bg {
    background-image: url(../../../assets/img/page/testimonial-background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

@media (max-width: 820px) {
  .testimonialsNew-place-bg {
    background-color: #0a253e;
  }
}

.testimonialsNew-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.2px;
  color: #F02469;
}

.testimonialsNew-sub-title {
  font-weight: 400;
  font-size: 36px;
  line-height: 54px;
  color: #FDFDFD;
}

.testimonialsNew-sub-title-bold {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  background: #92DDE2;
  color: #0A253E;
}

.testimonialsNew-underline {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #E6F3F4;
  position: relative;
  display: inline-block;
}

.testimonialsNew-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 1px solid #F02469;
}

.testimonialsNew-card {
  /* background: #FFFFFF !important; */
  box-shadow: 0 20px 60px rgb(10 30 81 / 4%) !important;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
}

.testimonialsNew-card {
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  min-height: 14rem;
  color: #0B2B33;
}

.testimonialsNew-card p {
  font-weight: 350;
  font-size: 20px;
  line-height: 36px;
  color: #FDFDFD;
  padding: 30px 20px;
}




.quote {
  text-align: center;
  font-size: 3.3rem;
  margin: auto;
  padding: 15px;
  border: 2px solid black;
  max-width: 70%;
  position: relative;
  margin-top: 100px;
}

blockquote {
  color: white;
  text-shadow: -1px 1px #555;
  font-style: italic;
  position: relative;
  z-index: 20;
}

.left {
  position: absolute;
  top: -70px;
  left: 20px;
  width: 0px;
  text-align: left;
  z-index: 10;
  font-size: 8rem;
  color: #336699;
  background-color: #ffcc00;
}

.right {
  position: absolute;
  bottom: -130px;
  right: 90px;
  width: 0px;
  text-align: right;
  z-index: 10;
  font-size: 8rem;
  color: #336699;
  background-color: #ffcc00;
  line-height: 200px;
}

/* small {
  font-size: 1.7rem;
  color: #336699;
  position: relative;
  z-index: 20;
}

small:before {
  content: "— ";
  width: 5px;
} */


.testimonialsNew-button {
  cursor: pointer;
  width: 40px;
  height: 35px;
  border-radius: 50%;
  /* font-size: 20px; */
  color: #fff;
  background: rgba(240, 36, 105, 0.8);
  border: none;
  text-align: center;
  vertical-align: middle;
  line-height: 37px;
  margin-top: 100px;
}