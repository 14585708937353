#faq .card {
    margin-bottom: 30px;
    border: 0;


    box-shadow: 0 4px 22px 0 rgba(31, 1, 71, 7%);
    transition: 0.3s;
    border-radius: 10px;
}

#faq .card .card-header {
    border: 0;
    -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    border-radius: 2px;
    padding: 0;
}

#faq .card .card-header .btn-header-link {
    color: #fff;
    display: block;
    text-align: left;
    background: #fff;
    color: #222;
    padding: 20px;
    font-weight: 700;
}

#faq .card .card-header .btn-header-link:after {
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: right;
}

#faq .card .card-header .btn-header-link.collapsed {
    background: #D2E9EB;
    color: #000000;
}

#faq .card .card-header .btn-header-link.collapsed:after {
    content: "\f106";
}

#faq .card .collapsing {
    /* background: #FFE472; */
    line-height: 30px;
}

#faq .card .collapse {
    border: 0;
}

#faq .card .collapse.show {
    background: #FFF;
    line-height: 30px;
    color: #222;
}



.pricing-section {
    /* border: 1px solid black; */
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 22px 0 rgba(31, 1, 71, 7%);
}

.pricing-section-subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
}

.pricing-section-header {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #0A253E;
    padding-top: 1rem;
}

.pricing-section-body {
    background-color: #FFFFFF;
    padding: 1rem;
}

.nav-section {
    font-size: 20px;
    font-weight: 600 !important;
    color: #ffffff;
    cursor: pointer !important;
    transition-duration: 0.5s;
    border-bottom: 4px solid transparent !important;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.nav-section:hover {
    /* border-bottom: 4px solid #222fb9 !important; */
    background-color: #10395f;
}

.nav-section-active {
    /* border-bottom: 4px solid #FFF !important; */
    background-color: #FFF;
    color: #f02469;

}



.plans-list {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    border-radius: 15px;
    border: solid 1px #E7ECEE;
    /* overflow: hidden; */
}

.plan {
    /* background-color: #dfdfdf; */
    position: relative;
    padding: 48px 36px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    scroll-snap-align: center;
    border-inline-start: inherit;
}

.plan .plan-animation {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
}

.plan:first-child {
    border-color: transparent;
}

.plan-select {
    background-color: #D2E9EB;
    border-color: transparent;
}

.plan-header {
    display: block;
}

.plan-label {
    height: 1em;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

.plan .plan-name {
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
    margin: 0.33em 0 0;
}

.plan .plan-prices {
    margin-top: 20px;
}

.plan .plan-prices .plan-price-wrapper {
    margin: 0;
}

.plan .plan-price {
    line-height: 1;
}

.plan .plan-price-currency {
    position: relative;
    font-size: 26px;
    vertical-align: top;
    top: 0.75em;
    letter-spacing: 0em;
}

.plan .plan-price-num {
    font-size: 84px;
    font-weight: 700;
    letter-spacing: -.05em;
}

.plan .plan-price-duration {
    opacity: .33;
    font-size: 18px;
    font-weight: 500;
}

.plan .plan-users-num {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: var(--color-blue-gray-75);
    margin-top: 1em;
}

.plan .plan-additional-users {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-blue-gray-50);
}

.plan-button-bordered-pink {
    text-align: center;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
    transition: .25s;
    border-radius: 999em;
    border: 2px solid;
    border-color: #ff6a9c;
    color: #f02469;
    padding: 0.67em 3em;
    justify-content: center;
    font-weight: 600;
}

.plan-button-bordered-pink:hover {
    border-color: #f02469;
    font-weight: 700;
    color: #f02469;
}

.highlights {
    display: block;
}

.plan .highlights h3 {
    font-size: 20px;
    font-weight: 600;
}

.plan .highlights .highlights-subtitle {
    font-weight: 600;
    color: var(--color-blue-dark);
}

.plan .highlights ul {
    list-style-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTMnIGhlaWdodD0nMTInIHZpZXdCb3g9JzAgMCAxMyAxMicgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTEuNTg4NSAwLjQyODg3M0wzLjkxNjc3IDguMzM2MDlMMS43OTIyOCA1Ljk3NTczQzAuOTY2MDk0IDUuMTQ5NiAtMC40NTAyMzEgNi4wOTM3NSAwLjEzOTkwNCA3LjI3MzkzTDIuNjE4NDcgMTEuNDA0NkMyLjk3MjU1IDExLjg3NjYgMy45MTY3NyAxMi40NjY3IDQuODYwOTkgMTEuNDA0NkM1LjIxNTA3IDEwLjkzMjUgMTIuNjUwOCAxLjYwOTA1IDEyLjY1MDggMS42MDkwNUMxMy41OTUgMC42NjQ5MDkgMTIuNDE0NyAtMC4yNzkyMzcgMTEuNTg4NSAwLjQyODg3M1onIGZpbGw9JyMwQjM4NTgnLz48L3N2Zz4K);
    -webkit-padding-start: 1em;
    padding-inline-start: 1em;
}

.plan .highlights li {
    -webkit-padding-start: 0.25em;
    padding-inline-start: 0.25em;

    font-size: 16px;
    line-height: 1.1875;
    margin-top: 1.4em;
}


.logo-gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 50px;
    /* background: teal; */
    padding: 15px;
    align-items: center;
}

.logo-gallery-container img {
    width: 100%;
    display: block;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.logo-gallery-container img:hover {
    transform: scale(1.04);
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}


.custom-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.custom-ul li {
    line-height: 1.5;
    text-indent: -30px;
    padding-left: 15px;
}

.custom-ul li:before {
    float: left;
    display: block;
    content: '\2605';
    margin: 0 30px 0 0;
}




.card-company {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    /* width: 180px; */
    /* height: 280px; */
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.03);
    transition: 0.2s ease-in-out;
}

.card-company:hover {
    transform: scale(1.03);
}

.card-company:hover .avatar {
    transform: scale(1.1);
    object-position: 50% 65%;
}


.card-company .avatar {
    width: 130px;
    height: 130px;
    object-fit: scale-down;
    /* object-position: 50% 50%; */
    margin: 10px;
    padding: 9px;
    /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3); */
    /* border-radius: 50%; */
    transition: 0.15s ease-in-out;
}

.card-company .details {
    margin-top: 10px;
}

.card-company .details p {
    font-size: 18px;
    color: #797687;
    font-weight: 300;
    /* line-height: 1.5; */
    /* margin-top: 10px; */
}

.card-company .company-name {
    font-weight: 600;
    color: #2b2350;
    font-size: 1.5rem;
    line-height: 1.2;
}