.card-wizard {
    background-color: #FFF;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-right: none;
}

span.e-label {
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
}

.card-wizard-header {
    font-weight: 700;
    font-size: 27px;
    line-height: 40px;
    letter-spacing: .2px;
    color: #0a253e;
}

.card-wizard-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #0a283e;
    opacity: .7;
}